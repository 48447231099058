import React from 'react';

import { Layout } from '../components/shared/Layout';
import { SEO } from '../components/shared/seo';
import { News } from '../components/pages/News/news';
import { graphql } from 'gatsby';
import { INewsItem } from '../types/news';

interface NewsDataProps {
  data: {
    posts: {
      edges: INewsItem[];
    };
  };
}

const Page: React.FC<NewsDataProps> = ({ data }) => {
  console.log(data);
  return (
    <Layout padded={true}>
      <SEO title="Nieuws" />
      <News posts={data.posts.edges} />
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query NewsPageQuery {
    posts: allSanityNewsitem(limit: 12, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              _id
            }
          }
          title
          slug {
            current
          }
        }
      }
    }
  }
`;
