import React from 'react';
import { Link } from 'gatsby';
import { INewsItem } from '../../../types/news';
import styles from './news.module.scss';
import { FaCalendarAlt } from 'react-icons/fa';

interface NewsDataProps {
  posts: INewsItem[];
}

export const News: React.FC<NewsDataProps> = ({ posts }) => {
  return (
    <>
      <div className="container">
        <h1 className={styles['news-list__title']}>Nieuws</h1>
        {posts && (
          <div className={styles['news-listitems']}>
            {posts.map((newsitem: INewsItem, index) => (
              <NewsItem item={newsitem} key={index} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

interface NewsItemProps {
  item: {
    node: INewsItem;
  };
}

const NewsItem: React.FC<NewsItemProps> = ({ item }) => {
  console.log(item.node);
  return (
    <div className={styles['news-listitem']}>
      <Link className={styles['news-listitem__link']} to={`/${item.node.slug.current}`}>
        <h3 className={styles['news-listitem__title']}>{item.node.title}</h3>
        <div className={styles['news-listitem__postdate']}>
          <FaCalendarAlt /> {item.node.post_date}
        </div>

        <p className={styles['news-listitem__subtitle']}>{item.node.subtitle}</p>
      </Link>
    </div>
  );
};
